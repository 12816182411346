
import {
  IonPage,
  IonHeader,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonTextarea,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import Header from "@/components/header.vue";
import { skipRouter } from "@/router";
import { ref, reactive, onMounted } from "vue";
import {
  workerDataCommit,
  getUserWorkerOrSupplierDetail,
  getDicSimpleList,
} from "@/service/member";
import { openTips, openModal } from "@/utils/alert";
import { userinfo, checklogin } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "PersonnelInfo",
  components: {
    Header,
    IonHeader,
    IonContent,
    IonPage,
    IonSelect,
    IonTextarea,
    IonSelectOption,
    IonInput,
  },
  setup() {
    const pagedataobj: any = ref([]);
    const woketypelistobj: any = ref([]);
    const professionlistobj: any = ref([]);
    const worksignlistobj: any = ref([]);
    const workyearslistobj: any = ref([]);

    const formdata: any = reactive({
      WorkerName: "",
      IdcardNo: "",
      WorkSign: "",
      Mobile: "",
      WorkType: "",
      Profession: "",
      WorkYears: "",
      AdressDetail: "",
      TuiJianRen: "",
      Remark: "",
    });

    const getUserWorkerOrSupplierDetailInfo = async () => {
      const loginuser = local.get("loginuser");
      console.log(loginuser);

      if (loginuser == null || loginuser.islogin != "1") {
        return;
      }
      const result = await getUserWorkerOrSupplierDetail({
        userid: loginuser.userid,
      });
      pagedataobj.value = result;

      formdata.WorkerId = pagedataobj.value.workerId;
      formdata.WorkerName = pagedataobj.value.workerName;
      formdata.Mobile = pagedataobj.value.mobile;
      formdata.IdcardNo = pagedataobj.value.idcardNo;
      formdata.WorkSign = String(pagedataobj.value.workSign);
      formdata.WorkType = String(pagedataobj.value.workType);
      formdata.Profession = String(pagedataobj.value.profession);
      formdata.WorkYears = String(pagedataobj.value.workYears);
      formdata.AdressDetail = pagedataobj.value.adressDetail;
      formdata.TuiJianRen = pagedataobj.value.tuiJianRen;
      formdata.Remark = pagedataobj.value.remark;
      console.log(formdata);
    };

    const submitWorker = async () => {
      console.log(formdata);
      if (formdata.WorkerName === "") {
        openTips("姓名不能为空!");
        return;
      }
      if (formdata.Mobile === "") {
        openTips("手机号不能为空!");
        return;
      }
      if (formdata.IdcardNo === "") {
        openTips("身份证号不能为空!");
        return;
      }
      if (formdata.WorkSign === "") {
        openTips("从业身份不能为空!");
        return;
      }
      if (formdata.WorkYears === "") {
        openTips("工作年限不能为空!");
        return;
      }
      if (formdata.Profession === "") {
        openTips("职业级别不能为空!");
        return;
      }
      if (formdata.WorkType === "") {
        openTips("技术类别不能为空!");
        return;
      }

      const result = await workerDataCommit(formdata);
      if (result) {
        openModal("恭喜您，提交成功!");
        skipRouter("/tabs/member");
      }
    };

    const getWorkTypeTeamSettingDetail = async () => {
      const result = await getDicSimpleList({
        enCode: "WorkType",
      });
      woketypelistobj.value = result;
      console.log(woketypelistobj.value);
    };
    const getProfessionTeamSettingDetail = async () => {
      const result = await getDicSimpleList({
        enCode: "Profession",
      });
      professionlistobj.value = result;
      console.log(professionlistobj.value);
    };
    const getWorkSignTeamSettingDetail = async () => {
      const result = await getDicSimpleList({
        enCode: "WorkSign",
      });
      worksignlistobj.value = result;
      console.log(worksignlistobj.value);
    };
    const getWorkYearsTeamSettingDetail = async () => {
      const result = await getDicSimpleList({
        enCode: "WorkYears",
      });
      workyearslistobj.value = result;
      console.log(workyearslistobj.value);
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      //检测是否登录
      checklogin();
      console.log("onIonViewDidEnter!");
      getWorkTypeTeamSettingDetail();
      getProfessionTeamSettingDetail();
      getWorkSignTeamSettingDetail();
      getWorkYearsTeamSettingDetail();
      getUserWorkerOrSupplierDetailInfo();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return {
      skipRouter,
      submitWorker,
      formdata,
      woketypelistobj,
      professionlistobj,
      worksignlistobj,
      workyearslistobj,
    };
  },
};
